<template>
  <div id="export-marketing-form-period">
    <h5 class="text-center">{{ title }} :</h5>
    <div class="card p-4">
      <div class="form-group mt-3">
        <Datepicker
            input-class="w-100 date-select"
            id="date-start"
            v-model="datepickerData.startDate"
            :format="datepickerData.formatDate"
            :language="datepickerData.language"
            @input="onChangeStartDate"
            placeholder="Date début"
        />
      </div>
      <div class="form-group">
        <Datepicker
            input-class="w-100 date-select"
            id="date-end"
            v-model="datepickerData.endDate"
            :format="datepickerData.formatDate"
            :language="datepickerData.language"
            @input="onChangeEndDate"
            placeholder="Date de fin"
        />
      </div>
    </div>
  </div>
</template>

<script>

import {fr} from 'vuejs-datepicker/dist/locale'
import moment from 'moment'

export default {
  props: {
    title: {
      type: String
    },
    isFirstPeriod: {
      type: Boolean
    }
  },
  data() {
    return {
      datepickerData: {
        startDate: null,
        endDate: null,
        formatDate: 'dd/MM/yyyy',
        language: fr
      }
    }
  },
  methods: {
    onChangeStartDate()
    {
      this.$emit('emit-on-change-start-date', {
        startDate : this.momentFormatDate(this.datepickerData.startDate, 'YYYY-MM-DD'),
        year: this.momentFormatDate(this.datepickerData.startDate, 'YYYY'),
        isFirstPeriod: this.isFirstPeriod
      })
    },
    onChangeEndDate()
    {
      this.$emit('emit-on-change-end-date', {
        endDate : this.momentFormatDate(this.datepickerData.endDate, 'YYYY-MM-DD'),
        year: this.momentFormatDate(this.datepickerData.endDate, 'YYYY'),
        isFirstPeriod: this.isFirstPeriod
      })
    },
    /*----------------- PRIVATE FUNCTION ------------------*/
    momentFormatDate(event, format) {
      return moment(event).format(format)
    },
  },
  components: {
    Datepicker: () => import('vuejs-datepicker')
  }
}
</script>
